#news{
    .news-items{
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        gap: 30px;
        .news-item{
            width: 33%;
            border: 0.5px solid #021D5333;
            border-radius: 20px;
            margin-bottom: 40px;
            .news-item-header{
                height: 320px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 20px 20px 0px 0px;
            }
            .news-item-body{
                margin-top: 20px;
                padding: 19px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .date-title{
                    font-size: 14.11px;
                    text-transform: uppercase;
                    color: #464646;
                }
                .news-title{
                    font-weight: 700;
                    font-size: 16px;
                    color: #021D53;
                }
                .news-body{
                    font-size: 14.11px;
                    line-height: 14.62px;
                }
                a{
                    color: #021D53;
                    text-decoration: underline;
                }
            }
        }
    }
    .news-letter-section{
        // height: 248px;
        width: 100%;
        margin: 49px auto;
        background: linear-gradient(90deg, #000000, #021D53);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 71px 52px ;
        gap: 70px;
        .news-letter-section-left{
            flex: 2;
            h3{
                font-size: 33.18px;
                font-weight: 700;
                color: #fff;
                margin-bottom: 10px;
            }
            p{
                color: #fff;
                font-size: 13px;
                text-align: justify;
                font-weight: 400;
            }
        }
        .news-letter-section-right{
            flex: 1;
            form{
                input[type=email]{
                    height: 48px;
                    width: 309px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    background: transparent;
                    border: 1px solid #fff;
                    color: #fff;
                    font-family: 'Inter', sans-serif;
                    &::placeholder{
                        font-weight: 500;
                        font-size: 13.33px;
                        color: #fff;
                        font-family: 'Inter', sans-serif;
                    }
                    margin-bottom: 8px;
                }
                input[type=submit]{
                    width: 309px;
                    height: 51px;
                    background-color: #1E438B;
                    font-size: 16px;
                    font-weight: 700;
                    color: white;
                    border: none;
                    line-height: 19.36px;
                    font-family: 'Inter', sans-serif;
                    &:hover{
                        cursor: pointer;
                        background-color: #1c48a0;
                    }
                }
            }
        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    #news{
        
        .news-letter-section{
            width: 90%;
            
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 769px){
    #news{
        .news-items{
            .news-item{
                width: 48%;
            }
        }
        .news-letter-section{
            width: 100%;
            flex-direction: column;
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    #news{
        .news-items{
            .news-item{
                width: 100%;
            }
        }
        
    }
}